import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../containers/Layout/Layout';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: black;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
`;

const NotFound = () => {
  return (
    <Layout>
      <Helmet>
        <title>Not Found | JB Marble Co.</title>
      </Helmet>
      <Wrapper>
        <h1>404</h1>
        <p>Page Not Found</p>
      </Wrapper>
    </Layout>
  );
};

export default NotFound;
